var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('center-title',{attrs:{"title":"产品浏览"}}),_c('router-link',{staticClass:"el-button whtie submit",attrs:{"to":"/selectmygoodslist"}},[_vm._v("产品浏览")]),_c('router-link',{staticClass:"el-button whtie submit",staticStyle:{"margin-bottom":"2%"},attrs:{"to":"/mygoodslist"}},[_vm._v("产品方案")]),_c('router-link',{staticClass:"el-button whtie submit",staticStyle:{"margin-bottom":"2%"},attrs:{"to":"/existinglist"}},[_vm._v("临时产品方案")]),_c('article',{staticClass:"bg-white"},[_c('div',{staticClass:"core condition"},[(_vm.categoryList.length)?_c('div',{staticClass:"condition-box mb30"},[_c('div',{staticClass:"condition-t row xs"},[_c('div',{staticClass:"title lighter"},[_vm._v("已选条件")]),_c('div',{staticClass:"t-content row-center"},[_c('span',{staticClass:"normal"},[_vm._v("分类：")]),(_vm.categoryOne == 0)?_c('span',{staticClass:"primary"},[_vm._v("全部")]):_vm._e(),(_vm.categoryOne != 0)?_c('span',{staticClass:"primary"},[_vm._v(_vm._s(_vm.categoryList[_vm.categoryOne - 1].name))]):_vm._e(),(_vm.categoryTwo != 0)?_c('span',{staticClass:"primary"},[_vm._v(" / "+_vm._s(_vm.categoryList[_vm.categoryOne - 1].sons[_vm.categoryTwo - 1].name))]):_vm._e(),(_vm.categoryThree != 0)?_c('span',{staticClass:"primary"},[_vm._v(" / "+_vm._s(_vm.categoryList[_vm.categoryOne - 1].sons[_vm.categoryTwo - 1].sons[ _vm.categoryThree - 1 ].name))]):_vm._e()])]),_c('div',{staticClass:"condition-b row xs"},[_c('div',{staticClass:"title lighter"},[_vm._v("一级分类")]),_c('div',{staticClass:"brand-list normal row wrap"},[_c('span',{class:{ active: _vm.categoryOne == 0 },on:{"click":function($event){(_vm.categoryOne = 0),
              (_vm.categoryTwo = 0),
              (_vm.categoryThree = 0),
              _vm.getGoodsList('all')}}},[_vm._v("全部")]),_vm._l((_vm.categoryList),function(item,index){return _c('span',{key:index,class:{ active: index + 1 == _vm.categoryOne },on:{"click":function($event){(_vm.categoryOne = index + 1),
                (_vm.categoryTwo = 0),
                (_vm.categoryThree = 0),
                _vm.getGoodsList(item.id)}}},[_vm._v(_vm._s(item.name))])})],2)]),(_vm.categoryOne != 0 && _vm.categoryList[_vm.categoryOne - 1])?_c('div',{staticClass:"condition-b row xs"},[_c('div',{staticClass:"title lighter"},[_vm._v("二级分类")]),_c('div',{staticClass:"brand-list normal row wrap"},[_c('span',{class:{ active: _vm.categoryTwo == 0 },on:{"click":function($event){(_vm.categoryTwo = 0),
              (_vm.categoryThree = 0),
              _vm.getGoodsList(_vm.categoryList[_vm.categoryOne - 1].id)}}},[_vm._v("全部")]),_vm._l((_vm.categoryList[_vm.categoryOne - 1].sons),function(item,index){return _c('span',{key:index,class:{ active: _vm.categoryTwo == index + 1 },on:{"click":function($event){(_vm.categoryTwo = index + 1),
                (_vm.categoryThree = 0),
                _vm.getGoodsList(item.id)}}},[_vm._v(_vm._s(item.name))])})],2)]):_vm._e(),(_vm.categoryOne != 0 &&
          _vm.categoryList[_vm.categoryOne - 1].sons[
          _vm.categoryTwo != 0 ? _vm.categoryTwo - 1 : 0
          ] && _vm.categoryTwo != 0
        )?_c('div',{staticClass:"condition-b row xs"},[_c('div',{staticClass:"title lighter"},[_vm._v("三级分类")]),_c('div',{staticClass:"brand-list"},[_c('span',{class:{ active: _vm.categoryThree == 0 },on:{"click":function($event){(_vm.categoryThree = 0),
              _vm.getGoodsList(
                _vm.categoryList[_vm.categoryOne - 1].sons[
                  _vm.categoryTwo != 0 ? _vm.categoryTwo - 1 : 0
                ].id
              )}}},[_vm._v("全部")]),_vm._l((_vm.categoryList[_vm.categoryOne - 1].sons[
              _vm.categoryTwo != 0 ? _vm.categoryTwo - 1 : 0
            ].sons),function(item,index){return _c('span',{key:index,class:{ active: _vm.categoryThree == index + 1 },on:{"click":function($event){(_vm.categoryThree = index + 1), _vm.getGoodsList(item.id)}}},[_vm._v(_vm._s(item.name))])})],2)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"pro-main row-between"},[_c('div',{staticClass:"pro-list",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"pro-condition row-between normal nr"},[_c('ul',{staticClass:"row"},[_c('li',{class:{ active: _vm.screen.default },on:{"click":function($event){(_vm.screen.sales = ''),
                (_vm.screen.default = true),
                (_vm.screen.price = ''),
                _vm.getGoodsList()}}},[_c('span',[_vm._v("综合排序")])]),_c('li',{staticClass:"row-center",class:{ active: _vm.screen.sales },on:{"click":function($event){(_vm.screen.sales = _vm.screen.sales == 'asc' ? 'desc' : 'asc'),
                (_vm.screen.default = false),
                (_vm.screen.price = ''),
                _vm.getGoodsList()}}},[_c('span',[_vm._v("销量")]),_c('img',{class:{
                  rotate: _vm.screen.sales && _vm.screen.sales == 'desc',
                },attrs:{"src":require(_vm.screen.sales    ? '@/assets/icon/icon-arrow9.png'    : '@/assets/icon/icon-arrow4.png'),"alt":""}})]),_vm._m(0),_vm._m(1),_c('li',{staticClass:"row-center price-box",class:{ active: _vm.screen.price },on:{"click":function($event){(_vm.screen.default = false),
                (_vm.screen.sales = ''),
                (_vm.screen.price = _vm.screen.price == 'asc' ? 'desc' : 'asc'),
                _vm.getGoodsList()}}},[_c('span',[_vm._v("价格")]),_c('div',{staticClass:"column arrow"},[_c('em',{class:{ active: _vm.screen.price == 'asc' }}),_c('em',{class:{ active: _vm.screen.price == 'desc' }})])])]),_c('div',{staticClass:"row input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],attrs:{"type":"text","placeholder":"请输入关键字"},domProps:{"value":(_vm.keyword)},on:{"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_c('button',{staticClass:"mr5",attrs:{"type":"primary"},on:{"click":function($event){return _vm.resetList(1)}}},[_vm._v("搜索")]),_c('button',{attrs:{"type":"reset"},on:{"click":function($event){_vm.keyword = '', _vm.resetList(1)}}},[_vm._v("清空")])])]),(_vm.goodsList.length)?_c('make-storeList',{staticClass:"list mb10",attrs:{"width":"236","height":"425","ph":"17","pv":"17","titleSize":"14","margin":"6","priceSize":20,"oldpriceShow":false,"prolists":true,"goodsList":_vm.goodsList}}):_vm._e(),(_vm.goodsList.length)?_c('make-pagination',{attrs:{"orderObject":_vm.orderObject},on:{"resetList":_vm.resetList}}):_c('el-empty',{staticClass:"mt30 flex1",attrs:{"description":"暂无商品"}})],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"row-center"},[_c('span',[_vm._v("评论数")]),_c('img',{attrs:{"src":require("@/assets/icon/icon-arrow4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"row-center"},[_c('span',[_vm._v("推荐")]),_c('img',{attrs:{"src":require("@/assets/icon/icon-arrow4.png"),"alt":""}})])
}]

export { render, staticRenderFns }