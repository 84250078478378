<template>


  

  <div class="store-list row wrap" :style="{ margin: -Number(margin) + 'px' }"  >
    <input type="hidden" name="plan_id" id="plan_id" value=""/>
    <router-link :to="``" class="store-item" 
      v-for="(item, index) in goodsList" :key="index" :style="{
        margin: margin + 'px',
        width: width + 'px',
        height: 360+ 'px',
        padding: ph + 'px ' + pv + 'px 0',
      }"  @click.native="selectGoods(item.id)">
      <div class="item-del" v-if="showDel" @click.prevent="$emit('delPro', item)">
        <img src="@/assets/icon/icon-del1.png" alt="" class="default" />
        <img src="@/assets/icon/icon-del2.png" alt="" class="active" />
      </div>
      <div class="item-pro">
        <el-image :src="item.image" fit="cover" class="img" lazy></el-image>
      </div>
      <div class="item-imglist row mb10" v-if="prolists">
        <el-image :src="baseUrl + items.uri" fit="cover" class="img" v-for="(items, indexs) in item.goods_image"
          :key="indexs" lazy></el-image>
      </div>
      <div class="item-title line2 normal " :style="{ fontSize: titleSize + 'px' }">
        {{ item.name }}
      </div>
      <!-- <div class="item-intro sm warn mb5 line1" v-if="prolists">
        设计简洁三款颜色可供选择
      </div> -->
      <div class="item-price">
        <make-priceformat :price="item.min_price" class="mr10" :subscriptSize="priceSize" :firstSize="priceSize"
          :secondSize="priceSize"></make-priceformat>
        <make-priceformat :price="item.market_price" :subscriptSize="oldpriceSize" :firstSize="oldpriceSize"
          :secondSize="oldpriceSize" color="#999999" v-if="oldpriceShow"></make-priceformat>
      </div>
      <!-- <div class="item-cart row-between mt20" v-if="join">
        <div class="cart-btn bg-cartbody cartc xs row-center">
          <img src="@/assets/icon/icon-cart2.png" alt="" class="icon-cart mr5" />
          <span>加入1购物车</span>
        </div>
        <el-input-number v-model="num" :min="1" :max="10"></el-input-number>
      </div> -->
    </router-link>
  </div>
</template>
<script>
import { h } from 'vue';

export default {
  props: {
    margin: {
      type: [Number, String],
      default: 10,
    },
    width: {
      type: [Number, String],
      default: 284,
    },
    height: {
      type: [Number, String],
      default: 425,
    },
    // 垂直
    ph: {
      type: [Number, String],
      default: 10,
    },
    // 水平
    pv: {
      type: [Number, String],
      default: 40,
    },
    // 标题字体大小
    titleSize: {
      type: [Number, String],
      default: 14,
    },
    // 加入购物车
    join: {
      type: Boolean,
      default: true,
    },
    // 价格字体大小
    priceSize: {
      type: [Number, String],
      default: 16,
    },
    // 旧价格字体大小
    oldpriceSize: {
      type: [Number, String],
      default: 16,
    },
    // 显示旧价格
    oldpriceShow: {
      type: Boolean,
      default: true,
    },
    // 显示简介/细图
    prolists: {
      type: Boolean,
      default: false,
    },
    //商品列表
    goodsList: {
      type: Array,
      default: () => {
        return [
   

        ];
      },
    },
    // 显示删除按钮
    showDel: {
      type: Boolean,
      default: false,
    },
    // 来源
    activityType: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      num: 1,
      baseUrl: process.env.VUE_APP_BASE_URL,
 
    };
  },
  methods: {


      
     selectGoods(id) {

  
      let plan_id= window.sessionStorage.getItem('plan_id');
    console.log("selectGoods+plan_id:"+plan_id);

      this.$api
        .selectMyGoodsApi({
          id,
          plan_id
        })
        .then(res => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          
         
          }
        });
    },
    created() {

    }
  },
};
</script>
<style lang="scss" scoped>
.store-list {
  padding-bottom: 170px;

  .store-item {
    background-color: $-color-white;
    position: relative;

    // margin-right: 21px;
    // margin-top: 21px;
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    .item-pro {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      margin-bottom: 10px;

      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .item-imglist {
      .img {
        margin-right: 4px;
        width: 37px;
        height: 37px;
        display: block;

        &:last-child {
          margin: 0;
        }
      }
    }

    .item-title {
      line-height: 1.5;
      margin-bottom: 5px;
    }

    .item-del {
      position: absolute;
      z-index: 2;
      width: 38px;
      height: 38px;
      right: 0;
      top: 0;

      &:hover {
        .default {
          display: none;
        }

        .active {
          display: block;
        }
      }

      .default {
        display: block;
      }

      .active {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .item-cart {
      .cart-btn {
        width: 115px;
        height: 35px;
        border: 1px solid $-color-cartc;

        .icon-cart {
          width: 17px;
          height: 17px;
        }
      }

      /deep/ .el-input-number {
        background: linear-gradient(0deg, #fafafa, #ededed);
        border: $-solid-border;
        box-sizing: border-box;
        width: 79px;
        height: 35px;
        line-height: 35px;
        color: $-color-black;

        .el-input-number__decrease,
        .el-input-number__increase {
          border: none;
          background-color: inherit;
          width: 27px;
          color: inherit;
          font-size: 12px;

          &.is-disabled {
            color: $-color-warn;
          }
        }

        .el-input__inner {
          height: 35px;
          border: none;
          background-color: inherit;
          padding: 0 27px;
          color: inherit;
        }
      }
    }
  }
}
</style>